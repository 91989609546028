import React, { Component } from "react";
import smoothScroll from "smoothscroll";
import classNames from "classnames";
import Band from "./Band";
import "./Home.scss";
import diyProjects from "../fixtures/projects/diy";
import chameledProjects from "../fixtures/projects/chameled";
import animalBandProjects from "../fixtures/projects/animalBand";
import Navbar from "../components/Navbar";
import Landing from "../components/Landing";
import Project from "../components/Project";
import Modal from "../components/Modal";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: null,
      images: [],
      hasReachedBeginning: false,
      hasReachedEnd: false
    };
  }

  handleClickImage(index, images) {
    this.setState({
      currentImageIndex: index,
      images,
      hasReachedBeginning: index === 0,
      hasReachedEnd: index === images.length - 1
    });
  }

  handleCloseImage() {
    this.setState({ currentImageIndex: null, images: [] });
  }

  handleScrollTo(link) {
    const workSection = this.workSection;
    const makeSection = this.makeSection;
    const playSection = this.playSection;
    switch (link) {
      case "work":
        if (workSection) {
          smoothScroll(workSection);
        }
        break;
      case "make":
        if (makeSection) {
          smoothScroll(makeSection);
        }
        break;
      case "play":
        if (playSection) {
          smoothScroll(playSection);
        }
        break;
      default:
        break;
    }
  }

  handleClickLeft() {
    const { currentImageIndex } = this.state;
    if (currentImageIndex > 0) {
      const prevIndex = currentImageIndex - 1;
      this.setState({
        currentImageIndex: prevIndex,
        hasReachedBeginning: prevIndex === 0,
        hasReachedEnd: false
      });
    }
  }

  handleClickRight() {
    const { currentImageIndex, images } = this.state;
    if (currentImageIndex < images.length - 1) {
      const nextIndex = currentImageIndex + 1;
      this.setState({
        currentImageIndex: nextIndex,
        hasReachedEnd: nextIndex === images.length - 1,
        hasReachedBeginning: false
      });
    }
  }

  handleClickPip(index) {
    const { images } = this.state;
    this.setState({
      currentImageIndex: index,
      hasReachedEnd: index === images.length - 1,
      hasReachedBeginning: index === 0
    });
  }

  render() {
    const {
      currentImageIndex,
      images,
      hasReachedBeginning,
      hasReachedEnd
    } = this.state;
    const currentImage = images[currentImageIndex];
    return (
      <div className="home-container">
        <Modal
          show={Boolean(currentImage)}
          onClick={this.handleCloseImage.bind(this)}
        >
          <div className="carousel">
            <div
              className="enlarged-image"
              onClick={this.handleCloseImage.bind(this)}
              style={{
                backgroundImage: `url(${currentImage && currentImage.src})`
              }}
            />
            <div className="carousel-navigation">
              <div
                onClick={this.handleClickLeft.bind(this)}
                className={classNames("carousel-navigate left", {
                  disabled: hasReachedBeginning
                })}
              >
                👈
              </div>
              <div className="carousel-pips">
                {images.map((image, index) => (
                  <div
                    onClick={this.handleClickPip.bind(this, index)}
                    className={classNames("carousel-pip", {
                      active: currentImageIndex === index
                    })}
                  />
                ))}
              </div>
              <div
                onClick={this.handleClickRight.bind(this)}
                className={classNames("carousel-navigate right", {
                  disabled: hasReachedEnd
                })}
              >
                👉
              </div>
            </div>
          </div>
        </Modal>
        <Navbar onClick={this.handleScrollTo.bind(this)} />
        <div className="sections">
          <Landing onClick={this.handleScrollTo.bind(this)} />
          <section
            ref={node => {
              this.workSection = node;
            }}
          >
            <div className="section-content full">
              <header>
                <h1>💻 Work</h1>
                <div className="links">
                  <a href="https://github.com/cranberryofdoom">Github</a>
                  <a href="https://www.linkedin.com/in/ashleyqian/">LinkedIn</a>
                  <a href="https://app.box.com/s/kb9fo3j7d3nawesy5dvc">
                    Resume
                  </a>
                </div>
              </header>
              <div className="projects">
                {diyProjects.map((project, index) => (
                  <Project
                    key={index}
                    project={project}
                    onClickImage={this.handleClickImage.bind(this)}
                  />
                ))}
              </div>
            </div>
          </section>
          <section
            ref={node => {
              this.makeSection = node;
            }}
          >
            <div className="section-content full">
              <header>
                <h1>🎨 Make</h1>
                <div className="links">
                  <a href="https://www.instagram.com/cranberryofdoom/">
                    Instagram
                  </a>
                  <a href="https://twitter.com/its_ashworks">Twitter</a>
                  <a href="https://medium.com/@its_ashworks">Medium</a>
                  <a href="https://www.youtube.com/user/ashleyqian0">Youtube</a>
                </div>
              </header>
              <div className="projects">
                {chameledProjects.map((project, index) => (
                  <Project
                    key={index}
                    project={project}
                    onClickImage={this.handleClickImage.bind(this)}
                  />
                ))}
                {animalBandProjects.map((project, index) => (
                  <Project
                    key={index}
                    project={project}
                    onClickImage={this.handleClickImage.bind(this)}
                  />
                ))}
              </div>
            </div>
          </section>
          <section
            ref={node => {
              this.playSection = node;
            }}
          >
            <div className="section-content full">
              <header>
                <h1>✨ Play</h1>
              </header>
              <Band />
            </div>
          </section>
        </div>
      </div>
    );
  }
}
