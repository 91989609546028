import React, { Component } from "react";
import classNames from "classnames";
import "./ProjectGallery.scss";

export default class ProjectGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalScroll: null,
      scrollerWidth: null
    };
  }

  componentDidMount() {
    this.setState({
      scrollerWidth: this.scrollerNode.offsetWidth,
      totalScroll: this.scrollerNode.scrollWidth,
      hasReachedEnd: false,
      hasReachedBeginning: true
    });
  }

  handleScrollLeft() {
    const { scrollerWidth, hasReachedBeginning } = this.state;
    const currentAmountScrolled = this.scrollerNode.scrollLeft;
    if (!hasReachedBeginning) {
      const newScrollPosition = currentAmountScrolled - scrollerWidth;
      this.scrollerNode.scrollLeft = newScrollPosition;
    }
  }

  handleScrollRight() {
    const { scrollerWidth, hasReachedEnd } = this.state;
    const currentAmountScrolled = this.scrollerNode.scrollLeft;
    if (!hasReachedEnd) {
      const newScrollPosition = currentAmountScrolled + scrollerWidth;
      this.scrollerNode.scrollLeft = newScrollPosition;
    }
  }

  handleScroll() {
    const { totalScroll } = this.state;
    const currentAmountScrolled = this.scrollerNode.scrollLeft;
    if (currentAmountScrolled === 0) {
      this.setState({ hasReachedBeginning: true, hasReachedEnd: false });
    } else if (
      currentAmountScrolled ===
      totalScroll - this.scrollerNode.offsetWidth
    ) {
      this.setState({ hasReachedBeginning: false, hasReachedEnd: true });
    } else this.setState({ hasReachedBeginning: false, hasReachedEnd: false });
  }

  render() {
    const { gallery, onClickImage, key } = this.props;
    const { hasReachedBeginning, hasReachedEnd } = this.state;
    const noScroll = gallery.images && gallery.images.length === 2;
    return (
      <article
        key={key}
        className={classNames("project-gallery-component", {
          two: noScroll
        })}
      >
        <div className="project-scroller">
          <div
            onClick={this.handleScrollLeft.bind(this)}
            className={classNames("project-scroll left", {
              disabled: hasReachedBeginning
            })}
          >
            👈
          </div>
          <h5>{gallery.description}</h5>
          <div
            onClick={this.handleScrollRight.bind(this)}
            className={classNames("project-scroll right", {
              disabled: hasReachedEnd
            })}
          >
            👉
          </div>
        </div>
        <div
          className="project-images"
          ref={node => {
            this.scrollerNode = node;
          }}
          onScroll={this.handleScroll.bind(this)}
        >
          <div className="project-images-wrapper">
            {gallery.images.map((image, index) => (
              <div className="project-image" key={image.src}>
                <img
                  onClick={onClickImage.bind(this, index, gallery.images)}
                  src={image.src}
                />
                {image.caption && (
                  <div className="project-caption">{image.caption}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </article>
    );
  }
}
