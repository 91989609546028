import React from "react";
import "./Project.scss";
import ProjectGallery from "./ProjectGallery";

export default function Project({ project, onClickImage }) {
  return (
    <div className="project-component">
      <div className="project-summary">
        <h2>{project.name}</h2>
        <div className="project-description">{project.description}</div>
        {project.links && (
          <div className="links">
            {project.links.map(link => (
              <a target="blank" key={link.url} href={link.url}>
                {link.cta}
              </a>
            ))}
          </div>
        )}
      </div>
      {project.gallery && (
        <div className="project-galleries">
          {project.gallery.map(group => (
            <ProjectGallery
              key={group.description}
              gallery={group}
              onClickImage={onClickImage.bind(this)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
